import "core-js/modules/es.array.push.js";
import { ref, onMounted, reactive, toRefs, onBeforeUnmount } from 'vue';
export default {
  props: {
    text: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const requestAnimationFrame = window.requestAnimationFrame;
    const cancelAnimationFrame = window.cancelAnimationFrame;
    const horizonRef = ref();
    const state = reactive({
      left: 0,
      textData: [],
      distance: 0,
      // 文字的宽度
      moveRaf: null // 动画回调标识
    });

    onMounted(() => {
      state.textData = [...props.text];
      setTimeout(() => {
        getElData();
      }, 0);
    });
    onBeforeUnmount(() => {
      cancelAnimationFrame(state.moveRaf); // 暂停动画
    });

    // 获取最大的文字宽度
    const getElData = () => {
      let temp = [];
      for (let i in horizonRef.value.children) {
        if (horizonRef.value.children[i].scrollWidth) {
          temp.push(horizonRef.value.children[i].scrollWidth);
        }
      }
      state.distance = Math.max(...temp) + 20;
      onStart(); // 开始滚动
    };
    // 向左移动
    const moveLeft = () => {
      if (state.left >= state.distance) {
        state.textData.push(state.textData.shift()); // 将第一条数据放到最后
        state.left = 0;
      } else {
        state.left += 1; // 每次移动1px
      }

      state.moveRaf = requestAnimationFrame(moveLeft);
    };
    // 开始动画
    const onStart = () => {
      state.moveRaf = requestAnimationFrame(moveLeft);
    };
    // 暂停动画
    const onStop = () => {
      cancelAnimationFrame(state.moveRaf);
    };
    return {
      onStop,
      onStart,
      horizonRef,
      ...toRefs(state)
    };
  }
};