import { onMounted, reactive, toRefs, getCurrentInstance, ref } from 'vue';
export default {
  props: {
    // 是否多张图片
    isMultiple: {
      type: Boolean,
      default: false
    },
    // 单张图片 对应的图片链接
    imgUrl: {
      type: String,
      default: ''
    },
    // 单张图片 或 多张图片字符串型数组 对应的图片名称
    imgName: {
      type: String,
      default: ''
    },
    // 多张图片 对应的图片数据
    pictureArr: {
      type: Array,
      default: () => []
    },
    // 多张图片对象型数组 对应的图片链接字段
    pictureArrUrlField: {
      type: String,
      default: 'url'
    },
    // 多张图片对象型数组 对应的图片名称字段
    pictureArrNameField: {
      type: String,
      default: 'name'
    },
    // 多张图片 对应的数组内数据类型
    arrayType: {
      type: String,
      default: 'object'
    }
  },
  emits: ['closePreview'],
  setup(props, context) {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      url: '',
      name: '',
      pictureIndex: 0
    });
    const image = ref(null);
    onMounted(() => {
      if (!props.isMultiple) {
        state.url = props.imgUrl;
        state.name = props.imgName;
      } else {
        state.pictureIndex = 0;
        processShowPicture();
      }
    });

    // 上一张
    const perviousPicture = () => {
      if (state.pictureIndex == 0) {
        state.pictureIndex = props.pictureArr.length - 1;
      } else {
        state.pictureIndex = state.pictureIndex - 1;
      }
      processShowPicture();
      clearImgStyle();
    };

    // 下一张
    const nextPicture = () => {
      if (state.pictureIndex == props.pictureArr.length - 1) {
        state.pictureIndex = 0;
      } else {
        state.pictureIndex = state.pictureIndex + 1;
      }
      processShowPicture();
      clearImgStyle();
    };

    // 处理展示的图片数据
    const processShowPicture = () => {
      if (props.arrayType === 'object') {
        state.url = props.pictureArr[state.pictureIndex][props.pictureArrUrlField];
        state.name = props.pictureArr[state.pictureIndex][props.pictureArrNameField];
      } else if (props.arrayType === 'string') {
        state.url = props.pictureArr[state.pictureIndex];
        state.name = props.imgName;
      }
    };

    // 清除图片样式
    const clearImgStyle = () => {
      image.value.style.transform = null;
      image.value.style.left = null;
      image.value.style.top = null;
    };

    // 关闭
    const handleClose = () => {
      context.emit("closePreview");
    };

    // 下载
    const downloadPic = () => {
      proxy.$downloadFile(state.url, state.name);
    };

    // 滚动鼠标滑轮
    const rollImg = e => {
      let transform = image.value.style.transform;
      let zoom = transform.indexOf("scale") != -1 ? +transform.split("(")[1].split(")")[0] : 1;
      zoom += e.wheelDelta / 1200;
      if (zoom > 0.1 && zoom < 7) {
        image.value.style.transform = "scale(" + zoom + ")";
      }
    };

    // 拖拽图片
    const moveImg = e => {
      let x = e.clientX;
      let y = e.clientY;
      let left = x - e.target.offsetLeft;
      let top = y - e.target.offsetTop;
      document.onmousemove = ev => {
        e.target.style.left = ev.clientX - left + 'px';
        e.target.style.top = ev.clientY - top + 'px';
      };
      document.onmouseup = () => {
        document.onmousemove = null;
      };
    };
    return {
      rollImg,
      moveImg,
      image,
      downloadPic,
      handleClose,
      perviousPicture,
      nextPicture,
      ...toRefs(state)
    };
  }
};