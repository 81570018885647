import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a6a3985a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "index"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "layout"
};
const _hoisted_4 = {
  class: "content",
  id: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CommonHeader = _resolveComponent("CommonHeader");
  const _component_tc_text_scroll = _resolveComponent("tc-text-scroll");
  const _component_CommonSidebar = _resolveComponent("CommonSidebar");
  const _component_Tags = _resolveComponent("Tags");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_CommonHeader), $setup.isShowWarnTips ? (_openBlock(), _createBlock(_component_tc_text_scroll, {
    key: 0,
    text: _ctx.text
  }, null, 8, ["text"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_CommonSidebar), _createElementVNode("div", _hoisted_3, [_createVNode(_component_Tags), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])])])]);
}