import { onMounted, ref, toRefs } from "vue";
export default {
  name: "MessageMain",
  props: {
    text: {
      type: String,
      default: ""
    },
    warnActive: {
      type: Boolean,
      default: false
    },
    warnText: {
      type: String,
      default: ""
    },
    confirmText: {
      type: String,
      default: ""
    },
    cancleText: {
      type: String,
      default: ""
    },
    colorText: {
      type: String,
      default: "#F91010"
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ["confirmFun", "cancelFun"],
  setup(props, content) {
    onMounted(() => {});
    function confirmFun() {
      content.emit("confirmFun");
    }
    function cancelFun() {
      content.emit("cancelFun");
    }
    return {
      confirmFun,
      cancelFun
    };
  }
};