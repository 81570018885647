import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/images/sidebar/expand-icon.svg';
const _withScopeId = n => (_pushScopeId("data-v-780224d5"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Menu = _resolveComponent("Menu", true);
  return _openBlock(), _createElementBlock("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, item => {
    return _openBlock(), _createElementBlock("li", {
      key: item.name
    }, [item.meta.isShowing ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass(["title", $setup.activeMenu == item.name ? 'active' : '']),
      style: _normalizeStyle({
        paddingLeft: $props.level == 1 ? '12px' : '32px'
      }),
      onClick: $event => $setup.changeRoute(item)
    }, [_createElementVNode("img", {
      src: item.meta.icon,
      alt: ""
    }, null, 8, _hoisted_2), _createTextVNode(" " + _toDisplayString(item.meta.title) + " ", 1), item.meta.isMulti ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: _imports_0,
      alt: "",
      class: "title-expand",
      style: _normalizeStyle({
        transform: _ctx.expandMenu == item.name ? 'rotate(180deg)' : ''
      })
    }, null, 4)) : _createCommentVNode("", true)], 14, _hoisted_1)) : _createCommentVNode("", true), item.meta.isMulti ? (_openBlock(), _createElementBlock("div", {
      key: 1,
      style: _normalizeStyle({
        gridTemplateRows: _ctx.expandMenu == item.name ? '1fr' : '0fr'
      }),
      class: "children"
    }, [_createVNode(_component_Menu, {
      list: item.children,
      level: $props.level + 1
    }, null, 8, ["list", "level"])], 4)) : _createCommentVNode("", true)]);
  }), 128))]);
}