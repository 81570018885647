import { computed, toRefs, reactive } from 'vue';
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    currentPageSize: {
      type: Number,
      default: 15
    }
  },
  setup(props, content) {
    const state = reactive({
      page: props.currentPage,
      pageSize: props.currentPageSize
    });
    const page = computed(() => {
      return props.currentPage;
    });
    function handleCurrentChange(val) {
      state.page = val;
      content.emit('handleCurrentChange', val);
    }
    function handleSizeChange(val) {
      state.pageSize = val;
      content.emit('handleSizeChange', val);
    }
    return {
      handleCurrentChange,
      handleSizeChange,
      ...toRefs(state)
    };
  }
};