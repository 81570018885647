import { onMounted, reactive, toRefs, getCurrentInstance, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Tags from './components/tags.vue';
const __default__ = {
  components: {
    Tags
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const route = useRoute();
    const store = useStore();
    const isShowWarnTips = computed(() => store.state.user.isShowWarnTips);
    const state = reactive({
      text: ['TC456254658748的货物有10件库龄过长，即将产生仓储费，请及时处理！', 'TC456254658748的货物有10件库龄过长，即将产生仓储费，请及时处理！', 'TC456254658748的货物有10件库龄过长，即将产生仓储费，请及时处理！', 'TC456254658748的货物有10件库龄过长，即将产生仓储费，请及时处理！', 'TC456254658748的货物有10件库龄过长，即将产生仓储费，请及时处理！', 'TC456254658748的货物有10件库龄过长，即将产生仓储费，请及时处理！'],
      height: 'calc(100vh - 98px)'
    });
    // 路由content区域跳转滚回顶部
    watch(() => route, () => {
      const dom = document.getElementById('content');
      dom.scrollTo(0, 0);
    }, {
      deep: true
    });
    onMounted(() => {
      if (isShowWarnTips.value) {
        state.height = 'calc(100vh - 140px)';
      }
    });
    return {
      isShowWarnTips,
      ...toRefs(state)
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "6ca1366f": _ctx.height
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;