import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a7cf6516"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "sidebar"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Menu = _resolveComponent("Menu");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Menu, {
    list: $setup.list,
    level: 1
  }, null, 8, ["list"])]);
}