import router from "./index";
import NProgress from "nprogress";
import 'nprogress/nprogress.css'
import store from "@/store";
import { computed, watch } from 'vue'
import { getLoginUserRuleTreeListAPI } from '@/api/user/user'
import TcMessage from '@/utils/common/tc-message'
// 动态路由
import { asyncRoutes } from './index'
let registerRouteFresh = true //防止刷新丢失
const userRouter = computed(() => store.state.user.userRouter)
const selectedPart = computed(() => store.state.user.selectedPart)

// 用户权限路由
let treeList = []
const getLoginUserRuleTreeList = async () => {
  try {
    treeList = await getLoginUserRuleTreeListAPI()
    // console.log('路由',treeList);
    changeUserRoutes(selectedPart.value)
  }
  catch (error) {
    console.log(error, '获取用户权限路由失败');
  }
}

// 更改用户路由
const changeUserRoutes = (val) => {
  // 当前模块所拥有的权限name集合
  const authList = []
  store.commit('user/clearSelectPartList')

  // 默认拥有价格模块
  let priceModule = { name: "price", label: "价格" }
  store.commit('user/addSelectPartList', priceModule)
  // 默认拥有跟踪模块
  let trackDownModule = { name: "trackDown", label: "跟踪" }
  store.commit('user/addSelectPartList', trackDownModule)

  treeList.map(module => {
    // 动态添加模块列表
    if (module.auth) {
      // 财务管理模块关闭
      if (module.name == 'finance') return
      store.commit('user/addSelectPartList', module)
    }
    // 无当前路径模块权限
    // if (!module.auth && localStorage.getItem("selectedPart") == module.name) {
    //     TcMessage({ type: "error", text: "无当前页面权限，已跳转到所拥有权限页面" })
    //     localStorage.removeItem("selectedPart")
    //     router.push('/')
    // }
    // 选择默认模块
    if (!localStorage.getItem("selectedPart") && store.state.user.selectPartList.length) {
      store.commit('user/changeSelectedPart', store.state.user.selectPartList[0].name)
    }
    // 当前模块所拥有的权限name集合
    if (module.name == val) {
      module.children.forEach(item => {
        if (item.auth) {
          authList.push(item.name)
        }
      })
    }
    // 发起工单权限
    // if (module.name == 'workOrder' && module.auth) {
    //     module.children.forEach(item => {
    //         if (item.name == 'workOrderList' && item.auth) {
    //             store.commit('user/changeSendWorkOrderStatus', true)
    //         }
    //     })
    // }
    // 一件代发权限
    // if (module.name == 'waybill' && module.auth) {
    //     module.children.forEach(item => {
    //         if (item.name == 'waybillAgentDelivery' && item.auth) {
    //             store.commit('user/changeAgentDeliveryStatus', true)
    //         }
    //     })
    // }
  })

  // 本地存储的模块不存在
  let index = store.state.user.selectPartList.findIndex(item => item.name == localStorage.getItem("selectedPart"))
  if (index == -1) {
    localStorage.removeItem("selectedPart")
    store.commit('user/changeSelectedPart', store.state.user.selectPartList[0].name)
  }

  // 每次新增路由时，开发阶段先authList.push路由的name，上线时把name、title给后端，然后再把这个删了
  // authList.push('storageSkuBatchStock')
  // authList.push('storageSkuStock')

  // 权限路由
  let userAllRoutes = []
  asyncRoutes.forEach(item => {
    if (item.name == val) {
      store.commit('user/clearUserRouter')
      // 价格模块默认拥有全部权限
      if (val === 'price') {
        item.children.forEach(childItem => {
          userAllRoutes.push(childItem)
        })
        return
      }
      item.children.forEach(childItem => {
        // 无分级路由 isMulti == false 通过name判断
        if (childItem.meta.isMulti == false) {
          if (authList.includes(childItem.name)) {
            userAllRoutes.push(childItem)
          }
        }
        // 分级路由 isMulti == true 进入children循环 通过meta.activeMenu判断
        else {
          userAllRoutes.push(childItem)
          let childRoutes = []
          childItem.children.forEach(child => {
            if (authList.includes(child.meta.activeMenu)) {
              childRoutes.push(child)
            }
          })
          childItem.children = childRoutes
        }
      })
    }
  })
  // isMulti == true 通过children是否为空判断
  userAllRoutes.forEach((item, index) => {
    if (item.meta.isMulti) {
      if (!item.children.length) {
        userAllRoutes.splice(index, 1)
      }
    }
  })
  // 添加路由
  userAllRoutes.forEach(item => {
    store.commit('user/addUserRouter', item)
    router.addRoute('index', item)
  })
}
// 模块改变重新获取用户路由
watch(() => store.state.user.selectedPart, (val) => {
  changeUserRoutes(val)
}, { deep: true })

// 路由守卫
router.beforeEach(async (to, from, next) => {
  if (to.path === "/print") return next()

  const authKey = localStorage.getItem("authKey") || ''
  NProgress.start()
 
  if (authKey) {
    await getLoginUserRuleTreeList()
    // URL手动更改时
    if (to.path === "/print") return next()

    const path = to.path.split('/')
    const part = store.state.user.selectPartList.find(item => item.name == path[1])
    if (part && path[1] != selectedPart.value) {
      store.commit('user/changeSelectedPart', path[1])
      localStorage.setItem("selectedPart", path[1])
    }
    if (to.path === "/login") {
      next({ path: "/" })
    } else {
      if (to.path == "/") {
        if (userRouter.value.length) {
          next({ path: userRouter.value[0].children[0].path })
        } else {
          let version = localStorage.getItem("version")
          localStorage.clear()
          localStorage.setItem('version', version)
          next('/login')
          location.reload()
        }
      }
      else {
        if (to.path === "/print") return next()
        if (registerRouteFresh) {
          registerRouteFresh = false;
          next({
            path: to.path,
            query: to.query
          })
        } else {
          next()
        }
      }

    }
  }
  else {
    if (to.path === "/print") return next()

    if (to.path === "/login") {
      registerRouteFresh = true;
      next()
      return
    }
    next("/login")
  }
  NProgress.done()
})

router.afterEach(() => {
  NProgress.done()
})