import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7cf68e00"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pagination"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_pagination, {
    background: "",
    "current-page": _ctx.page,
    "page-size": _ctx.pageSize,
    layout: "sizes,total, prev, pager, next,jumper",
    total: $props.total,
    "page-sizes": [15, 30, 60, 100],
    onCurrentChange: $setup.handleCurrentChange,
    onSizeChange: $setup.handleSizeChange
  }, null, 8, ["current-page", "page-size", "total", "onCurrentChange", "onSizeChange"])]);
}