import { onMounted, reactive, toRefs, getCurrentInstance, computed } from 'vue';
import { useStore } from 'vuex';
import Menu from './components/menu.vue';
const __default__ = {
  components: {
    Menu
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const store = useStore();
    const state = reactive({
      height: 'calc(100vh - 66px)'
    });
    const list = computed(() => store.state.user.userRouter);
    const isShowWarnTips = computed(() => store.state.user.isShowWarnTips);
    onMounted(() => {
      if (isShowWarnTips.value) {
        state.height = 'calc(100vh - 108px)';
      }
    });
    return {
      list,
      ...toRefs(state)
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "0f86474c": _ctx.height
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;