import Axios from "axios";

const axios = Axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    // timeout: 10 * 1000,
    withCredentials: false
});

//请求拦截器
axios.interceptors.request.use((config) => {
    let authKey = localStorage.getItem("authKey") || "";
    let version = localStorage.getItem("version") || "0.0.0";
    let SHELOGINKEY = localStorage.getItem("SHELOGINKEY") || "";
    let SheDataRefresh = localStorage.getItem("SheDataRefresh") || "normal";
    if (authKey) {
        config.headers["authKey"] = authKey;
    }
    if (version) {
        config.headers["version"] = version;
    }
    if (SHELOGINKEY) {
        config.headers["SHELOGINKEY"] = SHELOGINKEY;
    }
    if (SheDataRefresh) {
        config.headers["She-Data-Refresh"] = SheDataRefresh;
    }
    return config;
}, error => {
    return Promise.reject(error)
});

//响应拦截器
axios.interceptors.response.use(
    (response) => {
        const { data: res } = response;
        if (res.code === 0) {
            return Promise.resolve(res.data)
        }
        else if (response.status === 200 && response.config.responseType === 'blob') {
            return Promise.resolve(response)
        }
        else if (response.status === 268 && response.config.responseType === 'blob') {
            let data = response.headers.shereturnmsg
            return Promise.reject({ error: eval("'" + data + "'") })
        }
        else if (res.code === 302) {
            // 重定向
            localStorage.removeItem("authKey")
            location.reload("/login")
            return
        }
        else if (res.code == 308) {
            //强制刷新
            // let authKey = localStorage.getItem("authKey")
            // localStorage.clear()
            localStorage.setItem('version', res.data.new_version)
            localStorage.removeItem('selectedPart')
            localStorage.removeItem('tagList')
            // localStorage.setItem('authKey', authKey)
            location.reload()
            return
        }
        else {
            return Promise.reject(res)
        }
    },
    (error) => {
        const { response = {} } = error || {};
        return Promise.reject(response);
    }
);

export default axios;