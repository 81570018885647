import AccountList from '@/assets/images/sidebar/account-list.svg'
import AccountPrimary from '@/assets/images/sidebar/account-primary.svg'
import AccountAddress from '@/assets/images/sidebar/account-address.svg'
export default [
    {
        name: 'account',
        path: '/account',
        component: () => import("@/views/account"),
        redirect: "",
        children: [
            {
                name: 'accountList',
                path: '/account/list',
                component: () => import("@/views/account/list"),
                redirect: "/account/list/index",
                meta: {
                    title: '账号管理',
                    icon: AccountList,
                    isShowing: true,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'accountListIndex',
                        path: '/account/list/index',
                        component: () => import("@/views/account/list/list-index"),
                        meta: {
                            title: "账号管理",
                            isShowing: false,
                            activeMenu: 'accountList'
                        }
                    },
                    {
                        name: 'accountListNewAccount',
                        path: '/account/list/newAccount',
                        component: () => import("@/views/account/list/list-newAccount"),
                        meta: {
                            title: "创建账号",
                            isShowing: false,
                            activeMenu: 'accountList'
                        }
                    },
                    {
                        name: 'accountListEditAccount',
                        path: '/account/list/editAccount',
                        component: () => import("@/views/account/list/list-editAccount"),
                        meta: {
                            title: "编辑账号",
                            isShowing: false,
                            activeMenu: 'accountList'
                        }
                    },
                ]
            },
            {
                name: 'accountPrimary',
                path: '/account/primary',
                component: () => import("@/views/account/primary"),
                redirect: "/account/primary/index",
                meta: {
                    title: '主账号管理',
                    icon: AccountPrimary,
                    isShowing: true,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'accountPrimaryIndex',
                        path: '/account/primary/index',
                        component: () => import("@/views/account/primary/primary-index"),
                        meta: {
                            title: "主账号管理",
                            isShowing: false,
                            activeMenu: 'accountPrimary'
                        }
                    },
                ]
            },
            {
                name: 'accountAddress',
                path: '/account/address',
                component: () => import("@/views/account/address"),
                redirect: "/account/address/index",
                meta: {
                    title: '我的地址',
                    icon: AccountAddress,
                    isShowing: true,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'accountAddressIndex',
                        path: '/account/address/index',
                        component: () => import("@/views/account/address/address-index"),
                        meta: {
                            title: "我的地址",
                            isShowing: false,
                            activeMenu: 'accountAddress'
                        }
                    },
                ]
            }
        ]
    }
]