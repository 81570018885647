import FinanceUnpaid from '@/assets/images/sidebar/finance-unpaid.svg'
import FinanceStatement from '@/assets/images/sidebar/finance-statement.svg'
import StatementeBalance from '@/assets/images/sidebar/finance-balance.svg'
export default [
    {
        name: 'finance',
        path: '/finance',
        component: () => import("@/views/finance"),
        redirect: "/finance/unpaid",
        children: [
            {
                name: 'financeUnpaid',
                path: '/finance/unpaid',
                component: () => import("@/views/finance/unpaid"),
                redirect: "/finance/unpaid/index",
                meta: {
                    title: '待支付运单',
                    icon: FinanceUnpaid,
                    isShowing: true,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'financeUnpaidIndex',
                        path: '/finance/unpaid/index',
                        component: () => import("@/views/finance/unpaid/unpaid-index"),
                        meta: {
                            title: "待支付运单",
                            isShowing: false,
                            activeMenu: 'financeUnpaid'
                        }
                    },
                ]
            },
            {
                name: 'financeStatement',
                path: '/finance/statement',
                component: () => import("@/views/finance/statement"),
                redirect: "/finance/statement/index",
                meta: {
                    title: '财务流水',
                    icon: FinanceStatement,
                    isShowing: true,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'financeStatementIndex',
                        path: '/finance/statement/index',
                        component: () => import("@/views/finance/statement/statement-index"),
                        meta: {
                            title: "财务流水",
                            isShowing: false,
                            activeMenu: 'financeStatement'
                        }
                    },
                ]
            },
            {
                name: 'financeBalance',
                path: '/finance/balance',
                component: () => import("@/views/finance/balance"),
                redirect: "/finance/balance/index",
                meta: {
                    title: '账户余额',
                    icon: StatementeBalance,
                    isShowing: true,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'financeBalanceIndex',
                        path: '/finance/balance/index',
                        component: () => import("@/views/finance/balance/balance-index"),
                        meta: {
                            title: "账户余额",
                            isShowing: false,
                            activeMenu: 'financeBalance'
                        }
                    },
                ]
            },
        ]
    }
]