const user = {
    namespaced: true,
    state: {
        userRouter: [],
        userInfo: {},
        activeMenu: null,
        selectedPart: localStorage.getItem("selectedPart") || 'storage', // 待更改为所拥有权限的第一个
        modules: [],
        selectPartList: [],
        canAgentDelivery: false,
        canSendWorkOrder: false,
        // isInTrackDown: false,
        isShowWarnTips: false
    },
    mutations: {
        addUserRouter(state, data) {
            state.userRouter.push(data)
        },
        clearUserRouter(state) {
            state.userRouter = []
        },
        changeUserInfo(state, data) {
            state.userInfo = data
        },
        changeActiveMenu(state, data) {
            state.activeMenu = data
        },
        changeSelectedPart(state, data) {
            state.selectedPart = data
        },
        changeModules(state, data) {
            state.modules = data
        },
        addSelectPartList(state, data) {
            state.selectPartList.push(data)
        },
        clearSelectPartList(state) {
            state.selectPartList = []
        },
        changeAgentDeliveryStatus(state, data) {
            state.canAgentDelivery = data
        },
        changeSendWorkOrderStatus(state, data) {
            state.canSendWorkOrder = data
        },
        // changeIsInTrackDown(state, data) {
        //     state.isInTrackDown = data
        // },
        changeIsShowWarnTips(state, data) {
            state.isShowWarnTips = data
        },
    },
}

export default user
