import { reactive, toRefs, onMounted, getCurrentInstance } from 'vue';
const __default__ = {
  name: 'myTree',
  props: {
    treeData: {
      type: Array,
      default: () => []
    },
    nodeClick: {
      type: Function,
      default: () => {
        return;
      }
    },
    propsFontWeight: {
      type: Number,
      default: 700
    },
    propsShowLine: {
      type: Boolean,
      default: true
    },
    // 是否显示更多（···）按钮
    propsShowEllipse: {
      type: Boolean,
      default: false
    },
    // 计算层级
    propsCountLevel: {
      type: Number,
      default: 1
    },
    // 第N层隐藏操作按钮
    propsHideLevel: {
      type: Number,
      default: 10
    },
    propsHandleAddDepartment: {
      type: Function,
      default: () => {
        return;
      }
    },
    propsHandleEditDepartment: {
      type: Function,
      default: () => {
        return;
      }
    },
    propsHandleDeleteDepartment: {
      type: Function,
      default: () => {
        return;
      }
    }
  },
  setup(props, context) {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      selectedId: 0,
      totalShow: 0,
      showId: null,
      left: 0,
      top: 0
    });

    /**
    * 初始化数据，给子添加是否展开，用于计算虚线高度
    * @param {Array} data
    */
    // const initTreeData = (data) => {
    //   data.forEach((item) => {
    //     item.children && setTreeShowing(item.children)
    //   })
    // }
    // const setTreeShowing = (data) => {
    //   data.forEach((item) => {
    //     item.isShow = false;
    //     item.children && setTreeShowing(item.children)
    //   })
    // }

    // 处理点击
    const handleClickTitle = item => {
      props.nodeClick(item);
      state.selectedId === item.id ? state.selectedId = null : state.selectedId = item.id;
      renderStyle(item.id);
      // initTreeData(props.treeData);
      // if (state.selectedId === item.id) {
      //   state.selectedId = null;
      //   item.children && item.children.forEach(item => {
      //     item.isShow = false //收起
      //   })
      // } else {
      //   state.selectedId = item.id;
      //   item.children && item.children.forEach(item => {
      //     item.isShow = true //展开
      //   })
      // }
      // console.log(computeShowingChild(item));
    };

    // 计算展开的子
    // const computeShowingChild = (item) => {
    //   let showChildCount = 0;
    //   item.children ? showChildCount += countShow(item.children) : ""
    //   return showChildCount;
    // }
    // const countShow = (data) => {
    //   let childCount = 0;
    //   data.forEach(item => {
    //     item.isShow && childCount++;
    //   })
    //   return childCount
    // }

    // 添加样式
    const renderStyle = id => {
      let doms = document.querySelectorAll('.tc-tree-item');
      let curDom = document.getElementById(id);
      for (let i = 0; i < doms.length; i++) {
        if (doms[i] === curDom) continue;
        doms[i].children[0].className = 'item-title';
      }
      // toggle ClassName
      curDom.children[0].className.includes('title-active') ? curDom.children[0].className = "item-title" : curDom.children[0].className = "item-title title-active";
    };

    // 添加
    const handleAddDepartment = item => {
      props.propsHandleAddDepartment(item);
    };

    // 展示编辑删除面板
    const handleShow = (e, item) => {
      state.showId == item.id ? state.showId = null : state.showId = item.id;
      state.left = e.x - 40 + 'px';
      state.top = e.y + 15 + 'px';
    };
    const handleEditDepartment = item => {
      state.showId = null;
      props.propsHandleEditDepartment(item);
    };
    const handleDeleteDepartment = item => {
      state.showId = null;
      props.propsHandleDeleteDepartment(item);
    };
    onMounted(() => {});
    return {
      ...toRefs(state),
      handleClickTitle,
      // initTreeData,
      // computeShowingChild,
      renderStyle,
      handleAddDepartment,
      handleShow,
      handleEditDepartment,
      handleDeleteDepartment
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "35573f24": _ctx.left,
    "7b02d80e": _ctx.top
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;