import "core-js/modules/es.array.push.js";
import { onMounted, reactive, toRefs, getCurrentInstance, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      tagList: []
    });
    const selectedPart = computed(() => store.state.user.selectedPart);
    // 根据route变化tagList
    watch(() => route, val => {
      if (val.meta.title) {
        state.tagList.push({
          name: val.name,
          title: val.meta.title,
          path: val.fullPath
        });
        state.tagList = arrayUnique(state.tagList, 'name');
        localStorage.setItem("tagList", JSON.stringify(state.tagList));
      }
    }, {
      deep: true
    });
    onMounted(() => {
      // 得到tagList初始值
      if (!localStorage.getItem("tagList")) {
        state.tagList.push({
          name: route.name,
          title: route.meta.title,
          path: route.fullPath
        });
        localStorage.setItem("tagList", JSON.stringify(state.tagList));
      } else {
        state.tagList = JSON.parse(localStorage.getItem("tagList"));
      }

      // 当从没有标签栏的页面跳转到有标签栏的页面时，需要用到；
      let index = state.tagList.findIndex(item => item.name == route.name);
      if (index == -1) {
        state.tagList.push({
          name: route.name,
          title: route.meta.title,
          path: route.fullPath
        });
        localStorage.setItem("tagList", JSON.stringify(state.tagList));
      }
    });

    // 切换标签
    const changeRoute = item => {
      // 非当前模块跳转
      if (!item.path.includes(selectedPart.value)) {
        const path = item.path.split('/');
        store.commit('user/changeSelectedPart', path[1]);
        localStorage.setItem("selectedPart", path[1]);
      }
      setTimeout(() => {
        router.push(item.path);
      }, 0);
    };

    // 关闭标签栏
    const closeTag = item => {
      const index = state.tagList.findIndex(tag => tag.name == item.name);
      state.tagList.splice(index, 1);
      localStorage.setItem("tagList", JSON.stringify(state.tagList));
      // 关闭当前路由标签跳转到首项
      if (item.path == route.fullPath) {
        router.push(state.tagList[0].path);
        // 首项与当前所属模块一致
        if (state.tagList[0].path.includes(selectedPart.value)) {
          return;
        }
        // 首项与当前所属模块不一致
        const path = state.tagList[0].path.split('/');
        store.commit('user/changeSelectedPart', path[1]);
        localStorage.setItem("selectedPart", path[1]);
      }
    };

    // 数组去重保留第二次出现
    const arrayUnique = (arr, name) => {
      var hash = {};
      return arr.reduce(function (acc, cru, index) {
        if (!hash[cru[name]]) {
          hash[cru[name]] = {
            index: index
          };
          acc.push(cru);
        } else {
          acc.splice(hash[cru[name]]['index'], 1, cru);
        }
        return acc;
      }, []);
    };
    // 清空标签栏
    const cleanTagList = () => {
      state.tagList = [];
      state.tagList.push({
        name: route.name,
        title: route.meta.title,
        path: route.fullPath
      });
      localStorage.setItem("tagList", JSON.stringify(state.tagList));
    };
    return {
      route,
      changeRoute,
      closeTag,
      cleanTagList,
      ...toRefs(state)
    };
  }
};