import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4dd5a652"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "common-table"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, {
    ref: "multipleTableRef",
    border: "",
    data: $props.tableData,
    onSelectionChange: $setup.handleSelectionChange,
    "row-class-name": _ctx.$attrs.tableRowClassName
  }, {
    default: _withCtx(() => [$props.showSelection ? (_openBlock(), _createBlock(_component_el_table_column, {
      key: 0,
      type: "selection",
      width: "55"
    })) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3
  }, 8, ["data", "onSelectionChange", "row-class-name"])]);
}