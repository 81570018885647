import { reactive, toRefs, ref, onMounted, watch } from "vue";
export default {
  props: {
    showSelection: {
      type: Boolean,
      default: false
    },
    tableHeight: {
      type: String,
      default: '600px'
    },
    tableData: {
      type: Array,
      default: () => []
    },
    exitsData: {
      type: Array,
      default: () => []
    },
    // 是否传入默认勾选
    isDefalutSelected: {
      type: Boolean,
      default: false
    },
    judgeKey: {
      type: String,
      default: 'id'
    }
  },
  setup(props, context) {
    const state = reactive({
      multipleSelection: ""
    });
    const multipleTableRef = ref();

    // onMounted(() => {
    //   setTimeout(() => {
    //     if (props.isDefalutSelected) {
    //       toggleSelection(props.exitsData)
    //     }
    //   }, 200)
    // })

    watch(() => props.tableData, () => {
      if (props.isDefalutSelected) {
        setTimeout(() => {
          toggleSelection(props.exitsData);
        }, 100);
      }
    }, {
      deep: true
    });

    // 默认勾选
    const toggleSelection = rows => {
      if (rows) {
        rows.forEach(row => {
          props.tableData.forEach(item => {
            if (row[props.judgeKey] == item[props.judgeKey]) {
              multipleTableRef.value.toggleRowSelection(item, true);
            }
          });
        });
      }
    };
    const handleSelectionChange = val => {
      state.multipleSelection = val;
      context.emit("handleSelect", JSON.parse(JSON.stringify(val)));
    };
    return {
      ...toRefs(state),
      multipleTableRef,
      handleSelectionChange
    };
  }
};