export default (data, type, name, callback) => {
    const exportType = type == 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf'
    const blob = new Blob([data], {
        type: exportType,
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.download = name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    if (callback) {
        callback();
    }
}