import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

// 动态路由
import storage from './modules/storage'
import waybill from './modules/waybill'
import workOrder from './modules/workOrder'
import finance from './modules/finance'
import account from './modules/account'
import price from './modules/price'
export const asyncRoutes = [
  ...storage,
  ...waybill,
  ...workOrder,
  ...finance,
  ...account,
  ...price
]

export const constantRoutes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index'),
    children: []
  },
  {
    path: '/instruction',
    name: 'instruction',
    component: () => import('@/views/instruction')
  },
  {
    path: '/print',
    name: 'print',
    component: () => import('@/views/silentPrinting')
  },
  {
    path: '/trackDown',
    name: 'trackDown',
    component: () => import('@/views/trackDown')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    hide: true,
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error/404'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes,
})

export default router
