import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-23a0b426"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "common-upload"
};
const _hoisted_2 = {
  class: "upload-label"
};
const _hoisted_3 = {
  class: "picture-template"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = ["onClick"];
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "el-upload__tip"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_upload = _resolveComponent("el-upload");
  const _component_CommonPicturePreview = _resolveComponent("CommonPicturePreview");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$attrs.uploadLabel), 1), _createVNode(_component_el_upload, _mergeProps({
    class: "upload-demo",
    action: _ctx.baseURL + 'client/v3/upload/ossUpload',
    headers: _ctx.headers,
    "on-preview": $setup.handlePreview,
    "on-success": $setup.handleSuccess,
    name: "src",
    data: {
      dir: _ctx.dir
    },
    "on-remove": $setup.handleRemove
  }, _ctx.$attrs, {
    "on-progress": $setup.handleProgress,
    multiple: "",
    "on-exceed": $setup.handleExceed
  }), _createSlots({
    tip: _withCtx(() => [_createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$attrs.uploadTip), 1)]),
    default: _withCtx(() => [_createVNode(_component_el_button, {
      type: "primary"
    }, {
      default: _withCtx(() => [_createTextVNode("上传")]),
      _: 1
    })]),
    _: 2
  }, [_ctx.$attrs.listType === 'picture' ? {
    name: "file",
    fn: _withCtx(({
      file
    }) => [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
      class: "el-upload-list__item-thumbnail",
      src: file.request_url || file.url || file.src,
      alt: ""
    }, null, 8, _hoisted_4), _createElementVNode("p", {
      class: "picture-template-name",
      onClick: $event => $setup.handlePreview(file)
    }, _toDisplayString(file.name), 9, _hoisted_5), _createElementVNode("span", {
      class: "picture-template-close",
      onClick: $event => $setup.handleRemove(file)
    }, "x", 8, _hoisted_6)])]),
    key: "0"
  } : undefined]), 1040, ["action", "headers", "on-preview", "on-success", "data", "on-remove", "on-progress", "on-exceed"])]), _ctx.isPre ? (_openBlock(), _createBlock(_component_CommonPicturePreview, {
    key: 0,
    imgUrl: _ctx.preUrl,
    imgName: _ctx.preName,
    onClosePreview: _cache[0] || (_cache[0] = $event => _ctx.isPre = false)
  }, null, 8, ["imgUrl", "imgName"])) : _createCommentVNode("", true)], 64);
}