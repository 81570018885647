import TcMsg from "./tc-message"
/**
 * @param connector {string} 连接符，如'-',非必传
 * @return {[number,string]} 返回[总数，范围] 
 */
export default (start,end,connector)=>{
    if(!start || !end ){
        return ['','']
    }
    if(end < start){
        TcMsg({ type: 'error', text: "结尾数应大于或等于开始数！" })
        return ['','']
    }
    let rangeNum = end - start + 1
    let range = ''
    if(connector){
        range = start + connector + end
    }
    return [rangeNum,range]
}