import { reactive, toRefs, getCurrentInstance, computed } from 'vue';
const __default__ = {
  setup(props, content) {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      width: proxy.$attrs.width ? proxy.$attrs.width : '286px',
      height: proxy.$attrs.height ? proxy.$attrs.height : '24px'
    });
    const keyWord = computed({
      get() {
        return proxy.$attrs.value;
      },
      set(newVal) {
        let data = newVal;
        if (proxy.$attrs.type == 'daterange' && newVal == null) {
          data = [];
        } else if (proxy.$attrs.type == 'daterange' && newVal.length == 2) {
          data[1] = data[1].replace("00:00:00", "23:59:59");
        }
        content.emit("changeValue", data);
      }
    });
    // 限制只能选择今天及之前
    const disabledDate = time => {
      return time.getTime() > Date.now() - 8.64e6;
    };
    return {
      keyWord,
      disabledDate,
      ...toRefs(state)
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "e25ee8b0": _ctx.height,
    "162741b6": _ctx.width
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;