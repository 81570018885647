import "core-js/modules/es.array.push.js";
import { onMounted, reactive, toRefs, getCurrentInstance, watch, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
export default {
  name: 'Menu',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    level: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const {
      proxy
    } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      currentRoute: null,
      expandMenu: null
    });
    const activeMenu = computed(() => store.state.user.activeMenu);
    onMounted(() => {
      // console.log('props',props);
      // 初始路由样式
      if (props.level == 1) {
        store.commit('user/changeActiveMenu', route.meta.activeMenu);
      }
    });
    watch(() => route, val => {
      // 根据路由变化样式
      if (props.level == 1) {
        store.commit('user/changeActiveMenu', val.meta.activeMenu);
      }
    }, {
      deep: true
    });
    const changeRoute = item => {
      // 选中项
      state.currentRoute == item.name ? state.currentRoute = null : state.currentRoute = item.name;
      // 路由跳转
      if (item.children && item.children.length && item.meta.isMulti) {
        // 展开/隐藏子级
        state.expandMenu == item.name ? state.expandMenu = null : state.expandMenu = item.name;
        return;
      }
      router.push(item.path);
    };
    return {
      changeRoute,
      activeMenu,
      ...toRefs(state)
    };
  }
};