import WorkOrderList from '@/assets/images/sidebar/workOrder-list.svg'
import WorkOrderDrafts from '@/assets/images/sidebar/workOrder-drafts.svg'
export default [
    {
        name: 'workOrder',
        path: '/workOrder',
        component: () => import("@/views/workOrder"),
        redirect: "/workOrder/list",
        children: [
            {
                name: 'workOrderList',
                path: '/workOrder/list',
                component: () => import("@/views/workOrder/list"),
                redirect: "/workOrder/list/index",
                meta: {
                    title: '工单管理',
                    icon: WorkOrderList,
                    isShowing: true,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'workOrderListIndex',
                        path: '/workOrder/list/index',
                        component: () => import("@/views/workOrder/list/list-index"),
                        meta: {
                            title: "工单管理",
                            isShowing: false,
                            activeMenu: 'workOrderList'
                        }
                    },
                    {
                        name: 'workOrderListNewWorkOrder',
                        path: '/workOrder/list/newWorkOrder',
                        component: () => import("@/views/workOrder/list/list-newWorkOrder"),
                        meta: {
                            title: "新建工单",
                            isShowing: false,
                            activeMenu: 'workOrderList'
                        }
                    },
                    {
                        name: 'workOrderListDetail',
                        path: '/workOrder/list/detail',
                        component: () => import("@/views/workOrder/list/list-detail"),
                        meta: {
                            title: "工单详情",
                            isShowing: false,
                            activeMenu: 'workOrderList'
                        }
                    }
                ]
            },
            {
                name: 'workOrderDrafts',
                path: '/workOrder/drafts',
                component: () => import("@/views/workOrder/drafts"),
                redirect: "/workOrder/drafts/index",
                meta: {
                    title: '草稿箱',
                    icon: WorkOrderDrafts,
                    isShowing: true,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'workOrderDraftsIndex',
                        path: '/workOrder/drafts/index',
                        component: () => import("@/views/workOrder/drafts/drafts-index"),
                        meta: {
                            title: "草稿箱",
                            isShowing: false,
                            activeMenu: 'workOrderDrafts'
                        }
                    },
                ]
            }
        ]
    }
]