import ProductIcon from '@/assets/images/sidebar/storage-product.svg'
import StoreIcon from '@/assets/images/sidebar/storage-store.svg'
import BatchIcon from '@/assets/images/sidebar/storage-batch.svg'
import InventoryIcon from '@/assets/images/sidebar/storage-inventory.svg'
import DraftsIcon from '@/assets/images/sidebar/storage-drafts.svg'
import GoodsAgeingIcon from '@/assets/images/sidebar/storage-goodsAgeing.svg'
import PlatformProductIcon from '@/assets/images/sidebar/storage-platform-product.svg'
export default [
  {
    name: 'storage',
    path: '/storage',
    component: () => import("@/views/storage"),
    redirect: "/storage/product",
    children: [
      {
        name: 'storageProduct',
        path: '/storage/product',
        component: () => import("@/views/storage/product"),
        redirect: "/storage/product/index",
        meta: {
          title: '商品管理',
          icon: ProductIcon,
          isShowing: true,
          isMulti: false,
        },
        children: [
          {
            name: 'storageProductIndex',
            path: '/storage/product/index',
            component: () => import("@/views/storage/product/product-index"),
            meta: {
              title: '商品管理',
              isShowing: false,
              activeMenu: 'storageProduct'
            }
          },
          {
            name: 'storageProductNewSku',
            path: '/storage/product/newSku',
            component: () => import("@/views/storage/product/product-newSKU"),
            meta: {
              title: '新建SKU',
              isShowing: false,
              activeMenu: 'storageProduct'
            }
          },
          {
            name: 'storageProductEditSku',
            path: '/storage/product/editSku',
            component: () => import("@/views/storage/product/product-editSKU"),
            meta: {
              title: '编辑SKU',
              isShowing: false,
              activeMenu: 'storageProduct'
            }
          },
        ]
      },
      {
        name: 'storagePlatformProduct',
        path: '/storage/platformProduct',
        component: () => import("@/views/storage/platform-product"),
        redirect: "/storage/platformProduct/index",
        meta: {
          title: '平台商品',
          icon: PlatformProductIcon,
          isShowing: true,
          isMulti: false,
        },
        children: [
          {
            name: 'storagePlatformProductIndex',
            path: '/storage/platformProduct/index',
            component: () => import("@/views/storage/platform-product/platform-product-index"),
            meta: {
              title: '平台商品',
              isShowing: true,
              activeMenu: 'storagePlatformProduct'
            },
          }
        ]
      },
      {
        name: 'storageStore',
        path: '/storage/store',
        component: () => import("@/views/storage/store"),
        redirect: "/storage/store/list",
        meta: {
          title: '店铺管理',
          icon: StoreIcon,
          isShowing: true,
          isMulti: true,
        },
        children: [
          {
            name: 'storageStoreList',
            path: '/storage/store/list',
            component: () => import("@/views/storage/store/store-list"),
            meta: {
              title: '店铺列表',
              isShowing: true,
              activeMenu: 'storageStoreList'
            },
          },
          {
            name: 'storageStorePlatform',
            path: '/storage/store/platform',
            component: () => import("@/views/storage/store/store-platform"),
            meta: {
              title: '平台授权',
              isShowing: true,
              activeMenu: 'storageStorePlatform'
            },
          }
        ]
      },
      {
        name: 'storageDelivery',
        path: '/storage/delivery',
        component: () => import("@/views/storage/delivery"),
        redirect: "/storage/delivery/index",
        meta: {
          title: '批次管理',
          icon: BatchIcon,
          isShowing: true,
          isMulti: false,
        },
        children: [
          {
            name: 'storageDeliveryIndex',
            path: '/storage/delivery/index',
            component: () => import("@/views/storage/delivery/delivery-index"),
            meta: {
              title: '批次管理',
              isShowing: true,
              activeMenu: 'storageDelivery'
            },
          },
          {
            name: 'storageDeliveryDetail',
            path: '/storage/delivery/detail',
            component: () => import("@/views/storage/delivery/delivery-detail"),
            meta: {
              title: '发货详情',
              isShowing: false,
              activeMenu: 'storageDelivery'
            },
          },
        ]
      },
      {
        name: 'storageInventory',
        path: '/storage/inventory',
        component: () => import("@/views/storage/inventory"),
        redirect: "/storage/inventory/list",
        meta: {
          title: '库存管理',
          icon: InventoryIcon,
          isShowing: true,
          isMulti: true,
        },
        children: [
          {
            name: 'storageInventoryList',
            path: '/storage/inventory/list',
            component: () => import("@/views/storage/inventory/inventory-list"),
            meta: {
              title: '库存列表',
              isShowing: true,
              activeMenu: 'storageInventoryList'
            },
          },
          {
            name: 'storageInventoryDetail',
            path: '/storage/inventory/detail',
            component: () => import("@/views/storage/inventory/inventory-detail"),
            meta: {
              title: '库存详情',
              isShowing: false,
              activeMenu: 'storageInventoryList'
            },
          },
          {
            name: 'storageInventoryTransit',
            path: '/storage/inventory/transit',
            component: () => import("@/views/storage/inventory/inventory-transit"),
            meta: {
              title: '在途查看',
              isShowing: true,
              activeMenu: 'storageInventoryTransit'
            },
          },

          {
            name: "storageSkuBatchStock",
            path: "/storage/inventory/skuBatchStock",
            component: () => import("@/views/storage/inventory/sku-batch-stock"),
            meta: {
              title: "SKU批次库存",
              isShowing: true,
              activeMenu: 'storageSkuBatchStock'
            }
          },
          {
            name: "storageSkuStock",
            path: "/storage/inventory/skuStock",
            component: () => import("@/views/storage/inventory/sku-stock"),
            meta: {
              title: "SKU总库存",
              isShowing: true,
              activeMenu: 'storageSkuStock'
            }
          },
        ]
      },
      // {
      //     name: 'storageGoodsAgeing',
      //     path: '/storage/goodsAgeing',
      //     component: () => import("@/views/storage/goodsAgeing"),
      //     redirect: "/storage/goodsAgeing/index",
      //     meta: {
      //         title: '货物库龄',
      //         icon: GoodsAgeingIcon,
      //         isShowing: true,
      //         isMulti: false,
      //     },
      //     children: [
      //         {
      //             name: 'storageGoodsAgeingIndex',
      //             path: '/storage/goodsAgeing/index',
      //             component: () => import("@/views/storage/goodsAgeing/goodsAgeing-index"),
      //             meta: {
      //                 title: '货物库龄',
      //                 isShowing: true,
      //                 activeMenu: 'storageGoodsAgeing'
      //             },
      //         },
      //     ]
      // },
      {
        name: 'storageDrafts',
        path: '/storage/drafts',
        component: () => import("@/views/storage/drafts"),
        redirect: "/storage/drafts/index",
        meta: {
          title: '草稿箱',
          icon: DraftsIcon,
          isShowing: true,
          isMulti: false,
        },
        children: [
          {
            name: 'storageDraftsIndex',
            path: '/storage/drafts/index',
            component: () => import("@/views/storage/drafts/drafts-index"),
            meta: {
              title: '草稿箱',
              isShowing: true,
              activeMenu: 'storageDrafts'
            },
          },
        ]
      },
    ]
  }
]